<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="名称:">
                        <el-input v-model='filters.name' placeholder="请输入名称" clearable @clear='handleClear'></el-input>
                    </el-form-item>
                    <el-form-item label="数据类型:">
                        <el-select v-model="filters.status" clearable placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否启用:">
                        <el-select v-model="filters.isEnable" clearable placeholder="请选择">
                            <el-option
                                v-for="item in options3"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            v-loading="listLoading"
            @current-change="selectCurrentRow"
            highlight-current-row
            style="width: 100%;margin-bottom: 20px"
            >
            <el-table-column prop="Code" label="编号" align="center" width="200"></el-table-column>
            <el-table-column prop="Name" label="名称" align="center" width=""></el-table-column>           
            <el-table-column prop="Content" label="内容" align="center" width="" show-overflow-tooltip></el-table-column>
            <el-table-column prop="DataType" label="数据类型" width="100" align="center">
                <template scope="scope">
                    <el-tag v-if="scope.row.DataType == 0" type="success">行业</el-tag>
                    <el-tag v-if="scope.row.DataType == 1" type="warning">岗位</el-tag>
                    <el-tag v-if="scope.row.DataType == 2" type="">基础</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="TradeType" label="行业类型" width="100" align="center" show-overflow-tooltip>
                <template scope="scope">
                    <el-tag v-if="scope.row.DataType == 0 && scope.row.TradeType == 1" type="success">已分类行业</el-tag>
                    <el-tag v-else-if="scope.row.DataType == 0 && scope.row.TradeType == 0" type="warning">未设置专项考试行业</el-tag>
                    <el-tag v-else type="">-</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsRelation" label="是否关联行业" width="160" align="center">
                <template scope="scope">
                    <el-tag v-if="scope.row.IsRelation" type="success">是</el-tag>
                    <el-tag v-else type="warning">否</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="ReservedFieldString" label="预留字段String信息" align="center" width="180" show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="ReservedFieldInt" label="合格学习课时" align="center" width="160"></el-table-column>
            <el-table-column prop="IsEnable" label="启用标识" align="center" width="160">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable == true ? "primary" : "warning"'>{{scope.row.IsEnable == true ? "启用" : "禁用"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="Remarks" label="备注" align="center" width="" show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="isAddOrEdit?'新增':'编辑'"
        :visible.sync="addFormDialog"
        v-model="addFormDialog"
        :close-on-click-modal="false"
        >
            <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="150px" enctype="multipart/form-data">
<!--                <el-form-item label='编号:' prop='code'>-->
<!--                    <el-input v-model='dialogForm.code' :disabled="isAddOrEdit?false:true"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label='名称:' prop='name'>-->
<!--                    <el-input v-model='dialogForm.name'></el-input>-->
<!--                </el-form-item>-->
               
                <el-form-item label="数据类型:" prop='dataType'>
                    <el-select v-model="dialogForm.dataType" clearable placeholder="请选择" :disabled="isAddOrEdit?false:true" @change="changeDictionary">
                        <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
	            <el-form-item label="名称:" prop='code'>
		            <el-select v-model="dialogForm.code" clearable placeholder="请选择" :disabled="isAddOrEdit?false:true">
			            <el-option
					            v-for="item in dictionaryList"
					            :key="item.Value"
					            :label="item.Remark"
					            :value="item.Value"
			            ></el-option>
		            </el-select>
	            </el-form-item>
                <el-form-item label="行业类型:" prop='tradeType' v-if="dialogForm.dataType === 0">
                    <el-select v-model="dialogForm.tradeType" clearable placeholder="请选择">
                        <el-option
                            v-for="item in tradeTypeArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='排序:'>
                    <el-input v-model='dialogForm.sortNo'></el-input>
                </el-form-item>
                <el-form-item label='合格学习时长(时):' prop='learnTimeLen'>
                    <el-input type="number" v-model='dialogForm.learnTimeLen' :controls="false"></el-input>
                </el-form-item>
                <el-form-item v-if="dialogForm.dataType == 1" label="是否关联:" prop='isRelation'>
                    <el-select v-model="dialogForm.isRelation" placeholder="请选择">
                        <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='内容:' prop='content'>
                    <el-input v-model='dialogForm.content' type="textarea"></el-input>
                </el-form-item>
                <el-form-item label='备注:'>
                    <el-input v-model='dialogForm.remarks' type="textarea" :disabled="isAddOrEdit?false:true"></el-input>
                </el-form-item>
                <el-form-item label='启用标识:' prop='isEnable'>
                    <el-select v-model="dialogForm.isEnable" placeholder="请选择">
                        <el-option
                        v-for="item in options3"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
<!--	新增字典    -->
	    <el-dialog
			    title="新增字典"
			    :visible.sync="addDictionaryDialog"
			    v-model="addDictionaryDialog"
			    :close-on-click-modal="false"
	    >
		    <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="150px" enctype="multipart/form-data">
			    <el-form-item label='key值:' prop='content'>
				    <el-input v-model='dialogForm.content' ></el-input>
			    </el-form-item>
          <el-form-item label='编号:' prop='code'>
              <el-input v-model='dialogForm.code' ></el-input>
          </el-form-item>
          <el-form-item label='名称:' prop='name'>
              <el-input v-model='dialogForm.name'></el-input>
          </el-form-item>
			    <el-form-item label="数据类型:" prop='dataType'>
				    <el-select v-model="dialogForm.dataType" clearable placeholder="请选择" :disabled="isAddOrEdit?false:true" @change="changeDictionary">
					    <el-option
							    v-for="item in options4"
							    :key="item.namespace"
							    :label="item.name"
							    :value="item.namespace"
					    >
					    </el-option>
				    </el-select>
			    </el-form-item>
			    <el-form-item label='排序:'>
				    <el-input v-model='dialogForm.sortNo'></el-input>
			    </el-form-item>
			    <el-form-item label='启用标识:' prop='isEnable'>
				    <el-select v-model="dialogForm.isEnable" placeholder="请选择">
					    <el-option
							    v-for="item in options3"
							    :key="item.value"
							    :label="item.name"
							    :value="item.value">
					    </el-option>
				    </el-select>
			    </el-form-item>
		    </el-form>
		    <div slot="footer" class="dialog-footer">
			    <el-button @click.native="addDictionaryDialog = false">取消</el-button>
			    <el-button type="primary" @click.native="addSubmitdictionary" :loading="addLoading">保存</el-button>
		    </div>
	    </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import {
	getDataDictionaryList,
	addDataDictionary,
	updateDataDictionary,
	deleteDataDictionary,
	getExamPaperInfoTradeTypeList,
	getDeletedStaffsAuthoritySetting,
	saveDataDictionary
} from '../../api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        const checkLearnTimeLen = (rule,value,callback) => {          
            if(String(value).indexOf(0) == 0  &&  String(value).indexOf('.') == 1){
                return callback()
            }else if(String(value).indexOf(0) == 0 && String(value).length == 1) {
                return callback()
            } else if(String(value).indexOf('.') == 0 || String(value).indexOf('-') == 0){
                return callback(new Error('时间不正确'))
            }else if(String(value).indexOf(0) == 0  &&  String(value).indexOf('.') !== 1){
                return callback(new Error('时间不正确'))
            }else{
                return callback() 
            }
        }
        return {
	        addDictionaryDialog : false,
           dictionaryList:[],
            filters: {
                name: '',
                status: '',
                isEnable: '',
            },
            options: [
                {value:2,name:'基础'},
                {value:1,name:'岗位'},
                {value:0,name:'行业'},
            ],
            options1: [
                {value:2,name:'基础', namespace: 'BizService.ExamService.BaseCode'},
                {value:1,name:'岗位', namespace: 'BizService.ExamService.StationCode'},
                {value:0,name:'行业', namespace: 'BizService.ExamService.TradeCode'},
            ],
            options2: [
                {value:true,name:'是'},
                {value:false,name:'否'},
            ],
            options3:[
                {value:true,name:'启用'},
                {value:false,name:'禁用'},
            ],
	          options4: [{
		          name: '基础',
		          namespace: 'BizService.ExamService.BaseCode'
	          },{
		          name: '岗位',
		          namespace: 'BizService.ExamService.StationCode'
	          },{
		          name: '行业',
		          namespace: 'BizService.ExamService.TradeCode'
	          }],
            buttonList: [],
            tableData: [],
            tradeTypeArr: [
                {value: 1,name: '已分类行业'},
                {value: 0,name:'未设置专项考试行业'}
            ],
            listLoading: false,
            // 新增编辑数据
            isAddOrEdit:true,
            addFormDialog:false,
            addLoading:false,
            dialogForm:{
                name: '',
                code: '',
                dataType: '',
                isRelation: '',
                content: '',
                remarks: '',
                learnTimeLen: '',
                sortNo: '',
                isEnable:true,
                tradeType: '',
                dictionaryCode: '',
            },
            dialogFormRules:{
                name:[{required:true,message:'请输入名称',trigger:'blur'}],
                code:[{required:true,message:'请输入编码',trigger:'blur'}],
                dataType:[{required:true,message:'请选择数据类型',trigger:'blur'}],
                isRelation:[{required:true,message:'请选择是否关联',trigger:'blur'}],
                content:[{required:true,message:'请输入内容',trigger:'blur'}],
                tradeType:[{required:true,message:'请选择行业类型',trigger:'blur'}],
                learnTimeLen:[
                    {required:true,message:'请输入合格学习时长',trigger:'blur'},
                    {validator:checkLearnTimeLen,trigger:'blur'}
                ],
                isEnable:[{required:true,trigger:'blur'}]
            },
            needEditData: '',
        }
    },
    methods: {
			//提交数据字典
	    addSubmitdictionary(){
				let params = {
					IsDeleted:!this.dialogForm.isEnable,
					Key:this.dialogForm.content,
					Namespace:this.dialogForm.dataType,
					Remark:this.dialogForm.name,
					Sort:this.dialogForm.sortNo,
					Value:this.dialogForm.code,
				}
		    saveDataDictionary(params).then(res=>{
					if(res.data.Success){
						this.$message({
							message: '保存成功',
							type: 'success'
						})
						this.addDictionaryDialog = false
						this.getListData()
					}else{
						this.$message({
							message: res.data.Message,
							type: 'error'
						})
					}
		    })
	    },
				//新增字典
	    addDictionary(){
				this.dialogForm = {
					name: '',
					code: '',
					dataType: '',
					isRelation: '',
					content: '',
					remarks: '',
					learnTimeLen: '',
					sortNo: '',
					isEnable:true,
					tradeType: '',
					dictionaryCode: '',
				}
				this.addDictionaryDialog = true
	    },
        changeDictionary(item){
            var namespace = this.options1.find(option => option.value === item)?.namespace
            var params = {
                namespace: namespace
            }
            getDeletedStaffsAuthoritySetting(params).then((res) => {
                var result = res.data
                if(result.Success){
                  this.dictionaryList = result.Response
                }else{

                }
            })
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 清除input框，重新获取数据
        handleClear(){
            this.getListData()
        },
        // 获取分页列表
        getListData() {
            this.listLoading = true
            var params = {
                isEnable: this.filters.isEnable
            }
            if(this.filters.name){
                params.name = this.filters.name
            }
            if(this.filters.status !== 222){
                params.dataType = this.filters.status
            }
            getDataDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.tableData = result.Response
                    this.listLoading = false;
                }else{
                    this.listLoading = false;
                }
            })
        },
        // 查询
        getDataDictionaryList() {
            this.getListData();
        },
        // 新增
        handleAdd() {
            this.dialogForm = {
                name: '',
                code: '',
                dataType: '',
                tradeType: '',
                isRelation: '',
                learnTimeLen:'',
                content: '',
                remarks: '',
                sortNo: '',
                isEnable:true
            },
            this.isAddOrEdit = true
            this.addFormDialog = true
        },
        // 编辑
        handleEdit() {
            if(this.needEditData){
                var namespace = this.options1.find(option => option.value === this.needEditData.DataType)?.namespace
                var params = {
                  namespace: namespace
                }
                getDeletedStaffsAuthoritySetting(params).then((res) => {
                  var result = res.data
                  if(result.Success){
                    this.dictionaryList = result.Response
                  }else{

                  }
                })
                this.dialogForm = {
                    name:this.needEditData.Name,
                    code: this.needEditData.Code,
                    dataType: this.needEditData.DataType,
                    tradeType: this.needEditData.TradeType,
                    isRelation: this.needEditData.IsRelation,
                    content: this.needEditData.Content,
                    remarks: this.needEditData.Remarks,
                    learnTimeLen: this.needEditData.ReservedFieldInt,
                    sortNo: this.needEditData.SortNo,
                    isEnable:this.needEditData.IsEnable
                }
                this.isAddOrEdit = false;
                this.addFormDialog = true;
            }else{
                this.$message({
                    message: '请选择要编辑的数据',
                    type: 'warning'
                });
            }
        },
        // 删除
        handleDel() {
            if(this.needEditData){ 
                this.$confirm('确定删除？',"提示",{
                }).then(() => {
                    var params = {
                        Id: this.needEditData.Id
                    }
                    deleteDataDictionary(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message: '请选择要删除的数据',
                    type: 'warning'
                });
            }
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.needEditData = val
        },
        // 保存按钮
        addSubmit() {
            this.$refs['dialogFormRef'].validate(valid => {
                if(valid){
                    this.addLoading = true
                    var name = this.dictionaryList.find(option => option.Value === this.dialogForm.code.toString())?.Remark
                    var params = {
                        Name: name,
                        Code: this.dialogForm.code,
                        DataType: this.dialogForm.dataType,
                        TradeType: this.dialogForm.dataType === 0 ? this.dialogForm.tradeType : "",
                        Content: this.dialogForm.content,
                        SortNo: this.dialogForm.sortNo?this.dialogForm.sortNo:0,
                        Remarks: this.dialogForm.remarks?this.dialogForm.remarks:null,
                        IsEnable:this.dialogForm.isEnable
                    }
                    if(this.dialogForm.isRelation !== ''){
                        params.IsRelation = this.dialogForm.isRelation
                    }
                    if(this.dialogForm.learnTimeLen){
                        params.ReservedFieldInt = this.dialogForm.learnTimeLen
                    }
                    if(this.isAddOrEdit) {
                        this.toSubmitAddApi(params);
                    }else{
                        params.Id = this.needEditData.Id
                        this.toSubmitEdiApi(params);
                    }
                }
            })
        },
        toSubmitAddApi(params) {
            addDataDictionary(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                    this.addFormDialog = false
                    this.addLoading = false
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    var _this = this
                    setTimeout(() => {
                        _this.addLoading = false
                    })
                }
            })
        },
        toSubmitEdiApi(params) {
            updateDataDictionary(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                    this.addFormDialog = false
                    this.addLoading = false
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    var _this = this
                    setTimeout(() => {
                        _this.addLoading = false
                    })
                }
            })
        },
        // 获取行业类型
        getTradeTypeData() {
            var params = {}
            getExamPaperInfoTradeTypeList(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.tradeTypeArr = result.Response
                }
            })
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    watch: {
        // 'dialogForm.dataType': function(val) {
        //     if(val == 0) {
        //         this.getTradeTypeData()
        //     }
        // }
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 100px!important;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>